import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Table, Tag, Popconfirm, notification } from "antd";
import { delIp, getIpList } from "../../utils/httpClient/api";
import { AlertContent } from "../alert/index";

export const IpTable = forwardRef((props: any, ref: any) => {
  const placement = "topRight";
  const textTip = "是否移除？";
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      render: (text: any) => <a href="/#">{text}</a>,
    },
    {
      title: "所属项目",
      dataIndex: "projectName",
      key: "projectName",
      render: (project: string) => (
        <Tag
          color={
            project.length < 9
              ? "volcano"
              : project.length < 12
              ? "green"
              : "geekblue"
          }
          key={project}
        >
          {project}
        </Tag>
      ),
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <Popconfirm
          placement="top"
          title={textTip}
          onConfirm={() => deleteIp(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <a href="/#">移除</a>
        </Popconfirm>
      ),
    },
  ];
  const paginationProps = { pageSize: 15 };
  const [ipData, setipData] = useState([]);
  const [ifloading, setifloading] = useState(false);
  const [errContent, seterrContent] = useState<string>("");
  const [api, contextHolder] = notification.useNotification();
  const deleteIp = async (record: number) => {
    delIp(record)
      .then((res) => {
        api.info({ message: "移除成功", placement });
        InitData();
      })
      .catch((err) => {
        api.info({ message: err, placement });
        console.log(err);
      });
  };
  const InitData = async () => {
    setipData([]);
    getIpList()
      .then((res: any) => {
        res.data.forEach((item: any) => {
          const projectName =
            item.project === 1
              ? "DobotLab"
              : item.project === 2
              ? "DobotStudio2020"
              : "DobotLink";
          Object.assign(item, { key: item.id, projectName: projectName });
        });
        console.log(res.data);
        setipData(res.data);
      })
      .catch((error) => {
        showAlert(error.message);
        console.log(error.message);
      });
  };

  useEffect(() => {
    InitData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    RetTable,
  }));
  const RetTable = () => {
    setifloading(true);
    InitData().then(() => {
      const timer = setTimeout(() => {
        setifloading(false);
        clearTimeout(timer);
      }, 500);
    });
  };
  const showAlert = (content?: string) => {
    seterrContent(content || "");
    const timer = setTimeout(() => {
      seterrContent("");
      clearTimeout(timer);
    }, 2000);
  };

  return (
    <div>
      {contextHolder}
      {errContent && <AlertContent message="Error" description={errContent} />}
      <Table
        loading={ifloading}
        columns={columns}
        dataSource={ipData}
        rowClassName={(record, index) => {
          return index % 2 !== 1 ? "" : "table-sed";
        }}
        pagination={paginationProps}
      ></Table>
    </div>
  );
});
