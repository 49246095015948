import { forwardRef, useImperativeHandle, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Space, Drawer, Button, notification } from "antd";
import {
  AddNewLog,
  allLog,
  ChangeLogContent,
} from "../../utils/httpClient/api";
import TextArea from "antd/lib/input/TextArea";
import { Base64 } from "js-base64";

export const ChaLogTable = forwardRef((props: any, ref: any) => {
  const maxLength = 1024;
  const [visible, setvisible] = useState(false);
  const [buttonFlag, setbuttonFlag] = useState(false);
  const [title, setTitle] = useState("");
  const [versionId, setversionId] = useState(0);
  const [language, setlanguage] = useState(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [api, contextHolder] = notification.useNotification();
  const placement = "bottomRight";
  const reChangeInput = (event: any) => {
    setInputValue(event.target.value);
  };
  const ChangeLog = () => {
    if (!inputValue) {
      api.info({ message: "日志内容为空", placement });
      return;
    }
    buttonFlag ? refLog() : newLog();
  };
  const textTobase64 = (str: string) => {
    return Base64.encode(str);
  };
  const base64toText = (str: string) => {
    return Base64.decode(str);
  };
  const newLog = () => {
    AddNewLog(versionId, language, textTobase64(inputValue))
      .then((res: any) => {
        api.info({ message: "添加成功", placement });
        InitData(versionId, language);
      })
      .catch((error) => {
        api.info({ message: error, placement });
      });
  };
  const refLog = () => {
    ChangeLogContent(versionId, language, textTobase64(inputValue))
      .then((res: any) => {
        api.info({ message: "修改成功", placement });
        InitData(versionId, language);
      })
      .catch((error) => {
        api.info({ message: error, placement });
      });
  };
  const onClose = () => {
    setvisible(false);
  };
  useImperativeHandle(ref, () => ({
    onOpenChaLogTable,
  }));
  const onOpenChaLogTable = (
    version_id: number,
    language: number,
    version: string
  ) => {
    setvisible(true);
    setTitle(
      version + (language === 1 ? "  - 中文" : " - English") + " - ChangeLog"
    );
    InitData(version_id, language);
    setversionId(version_id);
    setlanguage(language);
  };
  const InitData = async (version_id: number, language: number) => {
    allLog(version_id, language)
      .then((res: any) => {
        if (res.data.length) {
          setInputValue(base64toText(res.data[0].content) || "");
          setbuttonFlag(true);
        } else {
          setInputValue("");
          setbuttonFlag(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Drawer
      title={title}
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Space direction="horizontal" style={{ marginTop: 10 }}>
            {contextHolder}
            <Button
              onClick={ChangeLog}
              type="primary"
              style={{ marginLeft: 200 }}
            >
              {buttonFlag ? "修改日志" : "新增日志"}
            </Button>
            <Button onClick={onClose} style={{ marginLeft: 10 }}>
              关闭
            </Button>
          </Space>
        </div>
      }
    >
      <TextArea
        placeholder="请输入日志内容"
        rows={30}
        value={inputValue}
        onChange={reChangeInput}
        maxLength={maxLength}
      />
    </Drawer>
  );
});
