import { getStorage, removeStorage, setStorage } from "../../utils/tool";

const initialState = {
  account: getStorage("selectPubAccount"),
  token: getStorage("selectPubToken"),
  ifDisbale: !getStorage("selectPubToken"),
};
const loginReducer = (state = initialState, action: any) => {
  if (action.type === "CHANGE_TOKEN" && action.params) {
    setStorage("selectPubToken", action.params.token);
    setStorage("selectPubAccount", action.params.account);
    return {
      ...state,
      account: action.params.account,
      token: action.params.token,
      ifDisbale: false,
    };
  } else if (action.type === "REMOVE_TOKEN") {
    removeStorage("selectPubToken");
    removeStorage("selectPubAccount");
    return {
      ...state,
      account: "",
      token: "",
      ifDisbale: true,
    };
  } else {
    return state;
  }
};
export default loginReducer;
