import { useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Space, Input, Dropdown, Button, Menu, notification } from "antd";
import { addIp } from "../../utils/httpClient/api";
import { useSelector } from "react-redux";

export const Footer = (prop: any) => {
  const menu = (
    <Menu
      onClick={(e) => {
        menuClick(e);
      }}
    >
      <Menu.Item key="1">
        <p>DobotLab</p>
      </Menu.Item>
      <Menu.Item key="2">
        <p>DobotStudio2020</p>
      </Menu.Item>
      <Menu.Item key="3">
        <p>DobotLink</p>
      </Menu.Item>
    </Menu>
  );
  const placement = "bottomRight";
  const { ifDisbale } = useSelector((state: any) => state.loginReducer);
  const [api, contextHolder] = notification.useNotification();
  const [inputValue, setInputValue] = useState<string>("");
  const [projectname, setProjectname] = useState<string>("");
  const [projectId, setprojectId] = useState<number>(1);

  const AddIp = async () => {
    if (!inputValue) {
      api.info({ message: "IP地址为空", placement });
      return;
    }
    addIp(inputValue, projectId)
      .then((res) => {
        api.info({ message: "添加IP成功", placement });
        prop.reFresh();
      })
      .catch((error) => {
        console.log(error);
        api.info({ message: error, placement });
      });
  };
  const reChangeInput = (event: any) => {
    setInputValue(event.target.value);
  };
  const menuClick = (e: any) => {
    switch (e.key) {
      case "1":
        setProjectname("DobotLab");
        setprojectId(1);
        break;
      case "2":
        setProjectname("DobotStudio2020");
        setprojectId(2);
        break;
      case "3":
        setProjectname("DobotLink");
        setprojectId(3);
        break;
    }
  };

  return (
    <Space direction="horizontal">
      {contextHolder}
      <Input
        placeholder="请输入IP地址"
        onPressEnter={() => AddIp()}
        value={inputValue}
        onChange={reChangeInput}
        allowClear
      />
      <Dropdown overlay={menu} placement="topCenter" arrow>
        <Button key="1" disabled={ifDisbale}>
          {projectname || "项目名称"}
        </Button>
      </Dropdown>
      <Button
        key="2"
        onClick={() => AddIp()}
        type="primary"
        disabled={ifDisbale}
      >
        添加IP
      </Button>
    </Space>
  );
};
