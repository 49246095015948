export const checkEqual = (a: any, b: any) =>
  JSON.stringify(a) === JSON.stringify(b);
export const deepClone = (a: any) => JSON.parse(JSON.stringify(a));
export const removeStorage = (action: string) => {
  localStorage.removeItem(action);
};
export const setStorage = (action: string, data: string) => {
  localStorage.setItem(action, data);
};
export const getStorage = (action: string) => {
  return localStorage.getItem(action);
};
