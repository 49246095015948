import { request, EHttpRequestType } from "./httpClient";

export interface drawDataType {
  version: string;
  url: string;
  project: number;
  status: number;
  environment: number;
}
export const getIpList = () => {
  return request({
    url: `/api/ip`,
    params: {},
    method: EHttpRequestType.GET,
  });
};
export const addIp = (ip: string, project: number) => {
  return request({
    url: `/api/ip`,
    params: { ip, project },
    method: EHttpRequestType.POST,
  });
};
export const delIp = (ipId: number) => {
  return request({
    url: `/api/ip`,
    params: [ipId],
    method: EHttpRequestType.DELETE,
  });
};

export const allVer = () => {
  return request({
    url: `/api/version/all`,
    params: {},
    method: EHttpRequestType.GET,
  });
};
export const retVer = (id: number, status: number) => {
  return request({
    url: `/api/version`,
    params: { id, status },
    method: EHttpRequestType.PUT,
  });
};
export const delVer = (VerId: number) => {
  return request({
    url: `/api/version`,
    params: [VerId],
    method: EHttpRequestType.DELETE,
  });
};
export const getNewVer = (project: number, environment: number) => {
  return request({
    url: `/api/version`,
    params: { project, environment },
    method: EHttpRequestType.GET,
  });
};
export const addNewVer = (drawDataType: any) => {
  return request({
    url: `/api/version`,
    params: drawDataType,
    method: EHttpRequestType.POST,
  });
};

export const allLog = (version_id: number, language: number) => {
  return request({
    url: `/api/changelog`,
    params: { version_id, language },
    method: EHttpRequestType.GET,
  });
};
export const AddNewLog = (
  version_id: number,
  language: number,
  content: string
) => {
  return request({
    url: `/api/changelog`,
    params: { version_id, language, content },
    method: EHttpRequestType.POST,
  });
};
export const ChangeLogContent = (
  version_id: number,
  language: number,
  content: string
) => {
  return request({
    url: `/api/changelog`,
    params: [{ version_id, language, content }],
    method: EHttpRequestType.PUT,
  });
};

export const newAccout = (params: any) => {
  return request({
    url: `/api/user/insert`,
    params,
    method: EHttpRequestType.POST,
    config: { isUser: false },
  });
};

export const loginAccout = (params: any) => {
  return request({
    url: `/api/user/login`,
    params,
    method: EHttpRequestType.POST,
    config: { isUser: false },
  });
};
