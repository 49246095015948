import { forwardRef, useRef, useState } from "react";
import { Button } from "antd";

import { ProSettings } from "@ant-design/pro-layout";
import ProLayout, { PageContainer } from "@ant-design/pro-layout";
import defaultProps from "./_defaultProps";
import {
  CloseCircleTwoTone,
  PlusOutlined,
  SafetyCertificateTwoTone,
  SettingTwoTone,
} from "@ant-design/icons";
import { ifProduction } from "../../utils/httpClient/env";
import { DrawerForm } from "../drawerDiago";
import { Footer } from "../footer";
import { IpTable } from "../ipTable";
import { VerTable } from "../verTable";
import { Login } from "../login";
import { useSelector } from "react-redux";
import { Logout } from "../logout";

export const Main = forwardRef((props: any, ref: any) => {
  const [settings] = useState<Partial<ProSettings> | undefined>({
    fixSiderbar: true,
    layout: "side",
    contentWidth: "Fluid",
    navTheme: "dark",
    splitMenus: false,
    primaryColor: "#1890ff",
    menuHeaderRender: false,
    fixedHeader: false,
  });
  const { ifDisbale } = useSelector((state: any) => state.loginReducer);
  const [pathname, setPathname] = useState("/welcome");
  const ifPro = ifProduction();

  const ipTableRef: any = useRef("IpTable");
  const verTableRef: any = useRef("VerTable");
  const DrawerForms: any = useRef("DrawerForm");
  const LoginRef: any = useRef("LoginRef");
  const LogoutRef: any = useRef("LogoutRef");
  const reFresh = () => {
    console.log("reFresh");
    pathname === "/ip" && ipTableRef.current.RetTable();
    pathname === "/version" && verTableRef.current.RetTable();
  };
  const newVersion = () => {
    DrawerForms.current.showDrawer();
  };
  const openLogin = () => {
    LoginRef.current.showLogin();
  };
  const LoginOut = () => {
    LogoutRef.current.showLogout();
  };
  const backWelcome = () => {
    setPathname("/welcome");
  };
  return (
    <div
      id="test-pro-layout"
      style={{
        height: "100vh",
      }}
    >
      <Login ref={LoginRef}></Login>
      <Logout ref={LogoutRef} backWelcome={backWelcome}></Logout>
      <DrawerForm ref={DrawerForms} reFresh={reFresh} />
      <ProLayout
        {...defaultProps}
        location={{
          pathname,
        }}
        onMenuHeaderClick={(e: any) => console.log(e)}
        menuItemRender={(item: any, dom: any) => (
          <a
            onClick={() => {
              setPathname(item.path || "/welcome");
            }}
            href="/#"
          >
            {dom}
          </a>
        )}
        {...settings}
      >
        <PageContainer
          extra={[
            <Button key="1" type="primary">
              <SettingTwoTone />
              当前环境 ：{ifPro ? "生产环境" : "非生产环境"}
            </Button>,
            <Button
              key="2"
              type="primary"
              onClick={() => newVersion()}
              disabled={ifDisbale}
            >
              <PlusOutlined /> 新增版本
            </Button>,
            <Button
              key="3"
              type="primary"
              onClick={() => reFresh()}
              disabled={ifDisbale}
            >
              刷新
            </Button>,
            ifDisbale ? (
              <Button key="4" type="primary" onClick={() => openLogin()}>
                <SafetyCertificateTwoTone /> 登录/注册
              </Button>
            ) : (
              <Button key="5" type="primary" onClick={() => LoginOut()}>
                <CloseCircleTwoTone /> 登出
              </Button>
            ),
          ]}
          footer={[<Footer reFresh={reFresh} key="footer" />]}
        >
          <div
            style={{
              height: "120vh",
            }}
          >
            {pathname === "/ip" ? (
              <IpTable ref={ipTableRef} />
            ) : pathname === "/version" ? (
              <VerTable ref={verTableRef} />
            ) : (
              ""
            )}
          </div>
        </PageContainer>
      </ProLayout>
    </div>
  );
});
