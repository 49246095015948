import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "antd/dist/antd.css";
import "./index.css";
import {
  Table,
  Tag,
  Space,
  Popconfirm,
  notification,
  Dropdown,
  Menu,
  Button,
} from "antd";
import { allVer, delVer, retVer } from "../../utils/httpClient/api";
import { ChaLogTable } from "./../chaLogDiago/index";
import { AlertContent } from "../alert";

export const VerTable = forwardRef((props: any, ref: any) => {
  const placement = "topRight";
  const changelogtextTip = "选择语言：";
  const textTip = "是否删除？";
  const paginationProps = { pageSize: 10 };
  const [verData, setverData] = useState([]);
  const [selectVerId, setselectVerId] = useState(1);
  const [ifloading, setifloading] = useState(false);
  const [errContent, seterrContent] = useState<string>("");
  const [api, contextHolder] = notification.useNotification();
  const menu = (
    <Menu
      onClick={(e) => {
        SelectChange(e.key);
      }}
    >
      <Menu.Item key="1">
        <a rel="noopener noreferrer" href="/#">
          正式发布
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a rel="noopener noreferrer" href="/#">
          灰度发布
        </a>
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: "版本号",
      dataIndex: "version",
      key: "version",
      render: (text: any) => <div>{text}</div>,
      width: 1,
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      key: "projectName",
      render: (project: string) => (
        <Tag
          color={
            project.length < 9
              ? "volcano"
              : project.length < 12
              ? "green"
              : "geekblue"
          }
          key={project}
        >
          {project}
        </Tag>
      ),
      width: 1.5,
    },
    {
      title: "部署环境",
      dataIndex: "environment",
      key: "environment",
      render: (text: any) => (
        <Tag
          color={
            text === 1
              ? "cyan"
              : text === 2
              ? "purple"
              : text === 3
              ? "processing"
              : "success"
          }
          key={text}
        >
          {text === 1
            ? "alpha"
            : text === 2
            ? "beta"
            : text === 3
            ? "rc"
            : "stable"}
        </Tag>
      ),
      width: 1,
    },
    {
      title: "发布状态",
      key: "status",
      render: (text: any) => (
        <Dropdown
          overlay={menu}
          placement="bottomCenter"
          arrow
          trigger={["click"]}
          key={text}
        >
          <Button onClick={() => dropDownIdChange(text.id)} key="1">
            {text.status === 1 ? "正式发布" : "灰度发布"}
          </Button>
        </Dropdown>
      ),
      width: 1,
    },
    {
      title: "下载url",
      dataIndex: "url",
      key: "url",
      render: (text: any) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
      width: 3,
      ellipsis: true,
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <Space direction="horizontal" size="middle">
          <Popconfirm
            placement="top"
            title={changelogtextTip}
            onConfirm={() => changelog(record.id, 2, record.version)}
            onCancel={() => changelog(record.id, 1, record.version)}
            okText="英文"
            cancelText="中文"
          >
            <a className="ChangeLog" href="/#">
              ChangeLog
            </a>
          </Popconfirm>
          <Popconfirm
            placement="top"
            title={textTip}
            onConfirm={() => deleteVer(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a className="del" href="/#">
              删除
            </a>
          </Popconfirm>
        </Space>
      ),
      width: 2,
    },
  ];

  const InitData = async () => {
    setverData([]);
    allVer()
      .then((res: any) => {
        res.data.forEach((item: any) => {
          const projectName =
            item.project === 1
              ? "DobotLab"
              : item.project === 2
              ? "DobotStudio2020"
              : "DobotLink";
          Object.assign(item, { key: item.id, projectName: projectName });
        });
        console.log(res.data);
        setverData(res.data);
      })
      .catch((error) => {
        showAlert(error.message);
        console.log(error.message);
      });
  };
  useEffect(() => {
    InitData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useImperativeHandle(ref, () => ({
    RetTable,
  }));
  const RetTable = () => {
    setifloading(true);
    InitData().then(() => {
      const timer = setTimeout(() => {
        setifloading(false);
        clearTimeout(timer);
      }, 500);
    });
  };

  const ChaLogTableRef: any = useRef("ChaLogTable");
  const changelog = (version_id: number, language: number, version: string) => {
    ChaLogTableRef.current.onOpenChaLogTable(version_id, language, version);
  };
  const deleteVer = async (record: number) => {
    delVer(record)
      .then((res) => {
        api.info({ message: "移除成功", placement });
        InitData();
      })
      .catch((err) => {
        api.info({ message: err, placement });
        console.log(err);
      });
  };
  const dropDownIdChange = (verId: number) => {
    setselectVerId(verId);
  };
  const SelectChange = async (e: string) => {
    retVer(selectVerId, Number(e))
      .then((res: any) => {
        api.info({ message: "修改成功", placement });
        const data = verData.map((item) => {
          if ((item as any).id === selectVerId) {
            (item as any).status = Number(e);
          }
          return item;
        });
        setverData(data);
      })
      .catch((error) => {
        api.info({ message: error, placement });
        console.log(error);
      });
  };
  const showAlert = (content?: string) => {
    seterrContent(content || "");
    const timer = setTimeout(() => {
      seterrContent("");
      clearTimeout(timer);
    }, 2000);
  };

  return (
    <div>
      {contextHolder}
      {errContent && <AlertContent message="Error" description={errContent} />}
      <Table
        loading={ifloading}
        scroll={{ x: "100%" }}
        columns={columns}
        dataSource={verData}
        rowClassName={(record, index) => {
          return index % 2 !== 1 ? "" : "table-sed";
        }}
        pagination={paginationProps}
      />
      <ChaLogTable ref={ChaLogTableRef} />
    </div>
  );
});
