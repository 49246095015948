import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  notification,
} from "antd";
import { addNewVer, drawDataType } from "../../utils/httpClient/api";
import { ifProduction } from "../../utils/httpClient/env";

export const DrawerForm = forwardRef((props: any, ref: any) => {
  const envOptions = ifProduction() ? ["stable"] : ["alpha", "beta", "rc"];
  const { Option } = Select;
  const drawData = {
    version: "",
    url: "",
    project: 0,
    status: 0,
    environment: 0,
  };
  const [drawVisible, setdrawVisible] = useState<boolean>(false);
  const [subData, setsubData] = useState<drawDataType>(drawData);
  useImperativeHandle(ref, () => ({
    showDrawer,
  }));
  const [api, contextHolder] = notification.useNotification();
  const placement = "bottomRight";
  const Formref: any = useRef("Formref");

  const InitDrawData = (data: drawDataType) => {
    data.version = "";
    data.url = "";
    data.project = 0;
    data.status = 0;
    data.environment = 0;
    return data;
  };
  const showDrawer = () => {
    setdrawVisible(true);
  };
  const onClose = () => {
    Formref.current.resetFields();
    setsubData(InitDrawData(subData));
    setdrawVisible(false);
  };

  const onSubmit = async () => {
    for (const item in subData) {
      if (!(subData as any)[item]) {
        api.info({ message: item + " 信息不完整", placement });
        return;
      }
    }
    addNewVer(subData)
      .then((res) => {
        api.info({ message: "新增版本成功", placement });
        props.reFresh();
        onClose();
      })
      .catch((error) => {
        console.log(error);
        api.info({ message: error, placement });
      });
  };

  const inputChange = (e: any) => {
    const data = subData;
    switch (e.target.id) {
      case "version":
        data.version = e.target.value;
        setsubData(data);
        break;
      case "url":
        data.url = e.target.value;
        setsubData(data);
        break;
    }
  };
  const proSelectChange = (e: any) => {
    const data = subData;
    data.project = e;
    setsubData(data);
  };
  const staSelectChange = (e: any) => {
    const data = subData;
    data.status = e;
    setsubData(data);
  };
  const stableENV = 4;
  const envSelectChange = (e: any) => {
    const data = subData;
    data.environment = ifProduction() ? stableENV : e;
    setsubData(data);
  };
  return (
    <Drawer
      title="新增版本"
      width={720}
      onClose={onClose}
      visible={drawVisible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
          key="drawer"
        >
          {contextHolder}
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            取消
          </Button>
          <Button onClick={onSubmit} type="primary">
            添加新版本
          </Button>
        </div>
      }
    >
      <Form layout="vertical" hideRequiredMark ref={Formref}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="version"
              label="version"
              rules={[{ required: true, message: "Please enter version" }]}
            >
              <Input
                placeholder="Please enter version"
                value={subData.version}
                onChange={inputChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="url"
              label="url"
              rules={[{ required: true, message: "Please enter url" }]}
            >
              <Input
                style={{ width: "100%" }}
                placeholder="Please enter url"
                value={subData.url}
                onChange={inputChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="project"
              label="project"
              rules={[{ required: true, message: "Please select a project" }]}
            >
              <Select
                placeholder="Please select a project"
                value={subData.project}
                onChange={proSelectChange}
              >
                <Option value="1">DobotLab</Option>
                <Option value="2">DobotStudio2020</Option>
                <Option value="3">DobotLink</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="status"
              rules={[{ required: true, message: "Please choose the status" }]}
            >
              <Select
                placeholder="Please choose the status"
                value={subData.status}
                onChange={staSelectChange}
              >
                <Option value="1" key="1">
                  正式发布
                </Option>
                <Option value="2" key="2">
                  灰度发布
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="environment"
              label="environment"
              rules={[
                { required: true, message: "Please choose the environment" },
              ]}
            >
              <Select
                placeholder="Please choose the environment"
                value={subData.environment}
                onChange={envSelectChange}
              >
                {envOptions.map((item, index) => {
                  return (
                    <Option value={index + 1} key={index}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
});
