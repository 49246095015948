import { Alert } from "antd";
import { forwardRef } from "react";
export const AlertContent = forwardRef((props: any, ref: any) => {
  const message = props.message || "";
  const description = props.description || "";
  const type = props.type || "error";
  return (
    <Alert
      message={message}
      description={description}
      type={type}
      showIcon
      closable
    />
  );
});
