import { forwardRef, useImperativeHandle, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Input, Button, Form, Modal, notification } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { AlertContent } from "../alert";
import { loginAccout, newAccout } from "../../utils/httpClient/api";
import store from "../../store";
import { useSelector } from "react-redux";

export const Login = forwardRef((props: any, ref: any) => {
  const [isModalVisible, setisModalVisible] = useState<boolean>(false);
  const [isRegister, setisRegister] = useState<boolean>(false);
  const [alertContent, setalertContent] = useState<string>("");
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const { ifDisbale } = useSelector((state: any) => state.loginReducer);
  useImperativeHandle(ref, () => ({
    showLogin,
    handleLogout,
  }));
  const showLogin = () => {
    setisModalVisible(!isModalVisible);
  };
  const changeModel = () => {
    setisRegister(!isRegister);
  };
  const handleLogin = async () => {
    const data = [form][0].getFieldsValue();
    loginAccout(data)
      .then((res: any) => {
        api.info({ message: res.status });
        if (res.data.token) {
          store.dispatch({
            params: { account: data.account, token: res.data.token },
            type: "CHANGE_TOKEN",
          });
          showLogin();
        }
      })
      .catch((err) => {
        showAlert(err.message);
        console.log(err);
      });
  };
  const handleRegister = async () => {
    if ([form][0].getFieldsValue().account.length < 3) {
      showAlert("账户名长度小于3!");
    } else if ([form][0].getFieldsValue().password.length < 6) {
      showAlert("密码长度小于6!");
    } else {
      const data = [form][0].getFieldsValue();
      newAccout(data)
        .then((res: any) => {
          api.info({ message: res.status });
          changeModel();
        })
        .catch((err) => {
          const message = err.message ? err.message : err;
          showAlert(message);
          console.log(message);
        });
    }
  };
  const handleLogout = async () => {
    showLogin();
    if (ifDisbale) {
      showAlert("未登录!");
    } else {
      store.dispatch({
        type: "REMOVE_TOKEN",
      });
      showAlert("已退出登录!");
    }
  };
  const showAlert = (content?: string) => {
    setalertContent(content || "");
    const timer = setTimeout(() => {
      setalertContent("");
      clearTimeout(timer);
    }, 2000);
  };
  return (
    <Modal
      title={isRegister ? "注 册" : "登 录"}
      visible={isModalVisible}
      centered={true}
      footer={null}
      onCancel={showLogin}
    >
      {contextHolder}
      {alertContent && <AlertContent description={alertContent} />}
      <Form
        className="login-form"
        form={form}
        onFinish={isRegister ? handleRegister : handleLogin}
      >
        账户名
        <FormItem name="account">{<Input />}</FormItem>
        密码
        <FormItem name="password">
          {<Input.Password type="password" />}
        </FormItem>
        {isRegister ? "注册码" : ""}
        {isRegister ? (
          <FormItem name="code">
            <Input />
          </FormItem>
        ) : (
          ""
        )}
        <FormItem className="center-content">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {isRegister ? "注 册" : "登 录"}
          </Button>
        </FormItem>
        {isRegister ? (
          <FormItem className="center-content">
            已有账号{" "}
            <a onClick={changeModel} href="/#">
              去 登录
            </a>
          </FormItem>
        ) : (
          <FormItem className="center-content">
            没有账号?{" "}
            <a onClick={changeModel} href="/#">
              去 注册
            </a>
          </FormItem>
        )}
      </Form>
    </Modal>
  );
});
