import axios from "axios";
import store from "../../store";
import { environmentHost } from "./env";
/*
 *url:请求的url
 *params:请求的参数
 *config:请求时的header信息
 *method:请求方法
 */
export enum EHttpRequestType {
  POST = "post",
  GET = "get",
  DELETE = "delete",
  PUT = "put",
}
type requestParamsType = {
  url: string;
  params: any;
  method: EHttpRequestType;
  config?: {};
};
const request = ({ url, method, params, config }: requestParamsType) => {
  const dataConfig = {
    url: environmentHost() + url,
    method: method,
    headers: config
      ? {
          "Content-Type": "application/json",
        }
      : {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + (store.getState().loginReducer.token || ""),
        },
  };
  if (method !== EHttpRequestType.GET) {
    Object.assign(dataConfig, { data: JSON.stringify(params) });
  } else {
    Object.assign(dataConfig, { params: params });
  }
  return new Promise((resolve, reject) => {
    axios(dataConfig)
      .then(
        (response: any) => {
          if (response.status === 200 && response.data.code === 0) {
            resolve(response.data);
          } else if (response.status === 200 && response.data.code === 403) {
            reject(response.data);
          } else {
            reject(response.data.message);
          }
        },
        (err: any) => {
          console.log(err);
          if (axios.isCancel(err)) {
          } else {
            reject(err);
          }
        }
      )
      .catch((err: any) => {
        console.log(err);
        if (axios.isCancel(err)) {
          return;
        }
        reject(err);
      });
  });
};
export { request };
