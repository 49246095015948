import { forwardRef, useImperativeHandle, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Button, Form, Modal, notification } from "antd";
import FormItem from "antd/lib/form/FormItem";
import store from "../../store";
import { useSelector } from "react-redux";

export const Logout = forwardRef((props: any, ref: any) => {
  const [isModalVisible, setisModalVisible] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const { ifDisbale, account } = useSelector(
    (state: any) => state.loginReducer
  );
  useImperativeHandle(ref, () => ({
    showLogout,
  }));
  const showLogout = () => {
    setisModalVisible(!isModalVisible);
  };
  const handleLogout = async () => {
    if (ifDisbale) {
      api.info({ message: "未登录!" });
    } else {
      store.dispatch({
        type: "REMOVE_TOKEN",
      });
      api.info({ message: "已退出登录!" });
      props.backWelcome();
    }
    const timer = setTimeout(() => {
      showLogout();
      clearTimeout(timer);
    }, 400);
  };
  return (
    <Modal
      title={"登 出"}
      visible={isModalVisible}
      centered={true}
      footer={null}
      onCancel={showLogout}
    >
      {contextHolder}
      <Form className="login-form">
        <FormItem className="center-content account-content">
          账户名: {account}
        </FormItem>
        <FormItem className="center-content">
          <Button
            type="primary"
            className="login-form-button"
            onClick={handleLogout}
          >
            登 出
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
});
